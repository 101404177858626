const initAnchorLink = () => {
  const links = [...document.querySelectorAll('.js-anchor-link')];

  if (!links.length) return;

  links.forEach(link => {
    const clickHandler = (e) => {
      e.preventDefault();
      const target = link.getAttribute('href');
      const hash = new URL(target, window.location.origin).hash;
      const targetElement = document.querySelector(hash);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }

    link.addEventListener('click', clickHandler);
  })
}

export default initAnchorLink;