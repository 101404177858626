import '../styles/style.scss'
import './utils/polyfills'
import './utils/detectTouch'
import './utils/responsiveHelper'
import ResizeHandler from './utils/resize-active'
import {initMobileNav, initNavDropdown} from './components/initMobileNav'
import initIntroSlider from './components/initIntroSlider'
import initSolutionsAccordion from './components/initSolutionsAccordion'
import initFeedbackSlider from './components/initFeedbackSlider'
import initLogoSlider from './components/initLogoSlider'
import initAosAnimations from './components/initAosAnimations'
import initCountUp from './components/initCountUp'
import initTabs from './components/initTabs'
import initFancybox from './components/initFancybox'
import initTimelineSlider from './components/initTimelineSlider'
import initEditableInput from './components/initEditableInput'
import initProductsSlider from './components/initProductsSlider'
import initShowPassword from './components/initShowPassword'
import initPhoneInput from './components/initPhoneInput'
import initApplyLoanForm from './components/initApplyLoanForm'
import initAccordion from './components/initAccordion'
import initAnchorLink from './components/initAnchorLink'

import 'intersection-observer' // Polyfill IntersectionObserver API for IE and old Safari
import LazyLoad from 'vanilla-lazyload' // Lazy load images plugin

const resizeHandler = new ResizeHandler()

/** Load Events */
window.addEventListener('DOMContentLoaded', () => {
  /**
   * Init lazyload and polyfill
   */
  const lazyLoadInsance = new LazyLoad()
  lazyLoadInsance.update()
  resizeHandler.init()

  initMobileNav();
  initNavDropdown();
  initIntroSlider();
  initSolutionsAccordion();
  initFeedbackSlider();
  initLogoSlider();
  initAosAnimations();
  initCountUp();
  initTabs();
  initFancybox();
  initTimelineSlider();
  initEditableInput();
  initProductsSlider();
  initShowPassword();
  initPhoneInput();
  initApplyLoanForm();
  initAccordion();
  initAnchorLink();
})
