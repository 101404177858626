import { CountUp } from 'countup.js';

export default function initCountUp() {
  const countItems = document.querySelector('.grid-numbers__list');

  if(countItems) {
    // Accessibility JS for count up statistic
    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) return;

    const options = {
      duration: 4,
      separator: '',
      enableScrollSpy: true,
      scrollSpyDelay: 300,
      scrollSpyOnce: true,
    }

    let counters = countItems.querySelectorAll('.js-counter');

    counters.forEach(counter => {
      let counterID = counter.getAttribute('id');
      let counterNumber = counter.getAttribute('data-countup');

      let countUp = new CountUp(counterID, counterNumber, options);
    })
  }
}