import Swiper from 'swiper';
import { Keyboard, A11y, Navigation } from "swiper/modules";

const initProductsSlider = () => {
  const productSliders = [...document.querySelectorAll('.js-products-slider')];

  if(!productSliders.length) return;


  productSliders.forEach((slider) => {
    const currentNumber = slider.querySelector('.js-current');

    const swiper = new Swiper(slider, {
      modules: [Keyboard, A11y, Navigation],
      loop: false,
      slidesPerView: 'auto',
      spaceBetween: 24,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      a11y: {
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
        notificationClass: 'swiper-notification visually-hidden'
      },
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      on: {
        slideChange: function () {
          const currentSlideIndex = swiper.realIndex;

          currentNumber.textContent = swiper.realIndex + 1;
        }
      }
    });
  })
}

export default initProductsSlider;
