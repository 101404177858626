import '../plugins/accordionPlugin';

export default function initSolutionsAccordion () {
  jQuery('.js-solutions-accordion').slideAccordion({
    opener: '.solutions-accordion__opener',
    slider: '.solutions-accordion__slide',
    activeClass: 'is-active',
    animSpeed: 300,
    progressLine: '.progress-line__inner',
    tabs: '.js-solutions-tabs',
    autoplay: true,
		collapsible: false,
    autoplaySpeed: 6000,
  });
}