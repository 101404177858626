export default function initShowPassword() {
  let buttons = document.querySelectorAll('.js-show-password');

  if (buttons.length == 0) return;

  buttons.forEach(button => {
    let input = button.parentElement.querySelector('input');

    button.addEventListener('click', function(e) {
      e.preventDefault();

      if (input.type == 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    })
  });
}
