import Swiper from 'swiper';
import { Keyboard, A11y, Navigation } from "swiper/modules";

const initTimelineSlider = () => {
  const swiper = new Swiper('.js-timeline-slider', {
    modules: [Keyboard, A11y, Navigation],
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 24,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      notificationClass: 'swiper-notification visually-hidden'
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
  });
}

export default initTimelineSlider;
