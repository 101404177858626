
import Swiper from 'swiper';
import { FreeMode, Autoplay } from "swiper/modules";

export default function initLogoSlider() {
  const sliders = document.querySelectorAll('.js-logo-slider');

  if (sliders.length == 0) return;

  sliders.forEach((slider, index) => {
    let reverseDirection = true;
    if (index % 2) {
      reverseDirection = false;
    }

    let swiper;

    swiper = new Swiper(slider, {
      modules: [FreeMode, Autoplay],
      spaceBetween: 0,
      allowTouchMove: false,
      freeMode: true,
      slidesPerView: 'auto',
      centeredSlides: false,
      loop: true,
      navigation: false,
      autoplay: {
        delay: 0,
        reverseDirection: reverseDirection,
      },
      speed: 6000,
    });
  })
}