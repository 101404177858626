/* eslint-disable */
/*
 * jQuery Accordion plugin new
 */
;(function(root, factory) {

	'use strict';
	if (typeof define === 'function' && define.amd) {
		define(['jquery'], factory);
	} else if (typeof exports === 'object') {
		module.exports = factory(require('jquery'));
	} else {
		root.SlideAccordion = factory(jQuery);
	}
}(window, function($) {

	'use strict';
	var accHiddenClass = 'js-acc-hidden';

	function SlideAccordion(options) {
		this.options = $.extend(true, {
			allowClickWhenExpanded: false,
			activeClass:'active',
			opener:'.opener',
			slider:'.slide',
			animSpeed: 300,
			autoplay: false,
      autoplaySpeed: 2000,
      progressLine: false,
      tabs:false,
			collapsible:true,
			event: 'click',
			scrollToActiveItem: {
				enable: false,
				breakpoint: 767, // max-width
				animSpeed: 600,
				extraOffset: null
			}
		}, options);
		this.init();
	}

	SlideAccordion.prototype = {
		init: function() {
			if (this.options.holder) {
				this.findElements();
				this.setStateOnInit();
				this.attachEvents();
        this.autoplay();
				this.makeCallback('onInit');
			}
		},

		findElements: function() {
			this.$holder = $(this.options.holder).data('SlideAccordion', this);
			this.$items = this.$holder.find(':has(' + this.options.slider + ')');

      if (this.options.tabs != false) {
			  this.$tabItems = $(this.options.tabs).find('>*');
      }
		},

		setStateOnInit: function() {
			var self = this;

			this.$items.each(function() {
				if (!$(this).hasClass(self.options.activeClass)) {
					$(this).find(self.options.slider).addClass(accHiddenClass);
				}
			});

      if (this.options.tabs != false) {
        this.$tabItems.each(function() {
          if (!$(this).hasClass(self.options.activeClass)) {
            $(this).hide();
          }
        });

        if (window.innerWidth < 768) {
          $(this.$tabItems[0]).show();
        }
      }
		},

		attachEvents: function() {
			var self = this;

			this.accordionToggle = function(e) {
				var $item = jQuery(this).closest(self.$items);
				var $actiItem = self.getActiveItem($item);

				if (!self.options.allowClickWhenExpanded || !$item.hasClass(self.options.activeClass)) {
					e.preventDefault();
					self.toggle($item, $actiItem);
				}
			};

			this.$items.on(this.options.event, this.options.opener, this.accordionToggle);
		},

		toggle: function($item, $prevItem) {
      let $itemIndex = this.getItemIndex($item);
      let $prevItemIndex = this.getItemIndex($prevItem);

			if (!$item.hasClass(this.options.activeClass)) {
				this.show($item);

        if (this.options.tabs != false) {
				  this.tabShow(jQuery(this.$tabItems[$itemIndex]));
        }
			} else if (this.options.collapsible) {
				this.hide($item);
			}

			if (!$item.is($prevItem) && $prevItem.length) {
				this.hide($prevItem);

        if (this.options.tabs != false) {
          this.tabHide(jQuery(this.$tabItems[$prevItemIndex]));
        }
			}

			this.makeCallback('beforeToggle');
		},

    autoplay: function() {
      if (this.options.autoplay == false) return;
      let self = this;

      let timer;

      var observer = new IntersectionObserver(entries => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            self.autoChangeSlide(self.getActiveIndex());
            timer = setInterval(function() {
              self.autoChangeSlide(self.getActiveIndex());
            }, self.options.animSpeed+self.options.autoplaySpeed);
          } else {
            clearInterval(timer);
          }
        });
      });
      
      observer.observe(self.$holder[0]);

      if (window.innerWidth < 768) return;

      self.$items.on('click', function() {
        if (self.options.autoplay == false) return;
        jQuery(this).find(self.options.progressLine).stop().css('width','0%').animate({
          width: '100%',
        }, self.options.animSpeed+self.options.autoplaySpeed, 'linear')
        clearInterval(timer);
        timer = setInterval(function() {
          self.autoChangeSlide(self.getActiveIndex());
        }, self.options.animSpeed+self.options.autoplaySpeed);
      });
    },

    autoChangeSlide: function($itemIndex) {
      if (window.innerWidth < 768) return;
      let $nextItemIndex;
      if (this.$items.length-1 == $itemIndex) {
        $nextItemIndex = 0;
      } else {
        $nextItemIndex = $itemIndex+1;
      }
      let $item = jQuery(this.$items[$nextItemIndex]);
      let $prevItem = jQuery(this.$items[$itemIndex]);
      this.toggle($item, $prevItem);
    },

    getActiveIndex: function() {
      let $item = jQuery(this.$holder).find('.' + this.options.activeClass);
      if ($item.length == 0) {
        $item = jQuery(this.$items[this.$items.length-1]);
      }
      let $activeItemIndex;
      this.$items.each(function(index) {
        if ($item[0] == jQuery(this)[0]) {
          $activeItemIndex = index;
        }
      });

      return $activeItemIndex;
    },

    getItemIndex: function($item) {
      let $itemIndex;
      this.$items.each(function(index) {
        if ($item[0] == jQuery(this)[0]) {
          $itemIndex = index;
        }
      });

      return $itemIndex;
    },

    tabShow: function($tabItem) {
      $tabItem.addClass(this.options.activeClass);
      $tabItem.stop().hide().removeClass(accHiddenClass).fadeIn({
        duration: this.options.animSpeed,
        complete: function() {
					$tabItem.removeAttr('style');
				}.bind(this)
      })
    },

		show: function($item) {
			var $slider = $item.find(this.options.slider);

			$item.addClass(this.options.activeClass);
			$slider.stop().hide().removeClass(accHiddenClass).slideDown({
				duration: this.options.animSpeed,
				complete: function() {
					$slider.removeAttr('style');
					if (
						this.options.scrollToActiveItem.enable &&
						window.innerWidth <= this.options.scrollToActiveItem.breakpoint
					) {
						this.goToItem($item);
					}
					this.makeCallback('onShow', $item);
				}.bind(this)
			});
      
      if (this.options.autoplay != false && window.innerWidth >= 768) {
        $item.find(this.options.progressLine).animate({
          width: '100%',
        }, this.options.animSpeed+this.options.autoplaySpeed, 'linear')
      } else {
        $item.find(this.options.progressLine).animate({
          width: '100%',
        }, 0)
      }

			this.makeCallback('beforeShow', $item);
		},

    tabHide: function($tabItem) {
      $tabItem.removeClass(this.options.activeClass);
      $tabItem.stop().show().fadeOut({
        duration: 0,
        complete: function() {
					$tabItem.addClass(accHiddenClass);
					$tabItem.removeAttr('style');
				}.bind(this)
      })
    },

		hide: function($item) {
			var $slider = $item.find(this.options.slider);

			$item.removeClass(this.options.activeClass);
			$slider.stop().show().slideUp({
				duration: this.options.animSpeed,
				complete: function() {
					$slider.addClass(accHiddenClass);
					$slider.removeAttr('style');
					this.makeCallback('onHide', $item);
				}.bind(this)
			});
      $item.find(this.options.progressLine).stop().css('width', '0%');

			this.makeCallback('beforeHide', $item);
		},

		goToItem: function($item) {
			var itemOffset = $item.offset().top;

			if (itemOffset < $(window).scrollTop()) {
				// handle extra offset
				if (typeof this.options.scrollToActiveItem.extraOffset === 'number') {
					itemOffset -= this.options.scrollToActiveItem.extraOffset;
				} else if (typeof this.options.scrollToActiveItem.extraOffset === 'function') {
					itemOffset -= this.options.scrollToActiveItem.extraOffset();
				}

				$('body, html').animate({
					scrollTop: itemOffset
				}, this.options.scrollToActiveItem.animSpeed);
			}
		},

		getActiveItem: function($item) {
			return $item.siblings().filter('.' + this.options.activeClass);
		},

		makeCallback: function(name) {
			if (typeof this.options[name] === 'function') {
				var args = Array.prototype.slice.call(arguments);
				args.shift();
				this.options[name].apply(this, args);
			}
		},

		destroy: function() {
			this.$holder.removeData('SlideAccordion');
			this.$items.off(this.options.event, this.options.opener, this.accordionToggle);
			this.$items.removeClass(this.options.activeClass).each(function(i, item) {
				$(item).find(this.options.slider).removeAttr('style').removeClass(accHiddenClass);
			}.bind(this));
			this.makeCallback('onDestroy');
		}
	};

	$.fn.slideAccordion = function(opt) {
		var args = Array.prototype.slice.call(arguments);
		var method = args[0];

		return this.each(function() {
			var $holder = jQuery(this);
			var instance = $holder.data('SlideAccordion');

			if (typeof opt === 'object' || typeof opt === 'undefined') {
				new SlideAccordion($.extend(true, {
					holder: this
				}, opt));
			} else if (typeof method === 'string' && instance) {
				if(typeof instance[method] === 'function') {
					args.shift();
					instance[method].apply(instance, args);
				}
			}
		});
	};

	(function() {
		var tabStyleSheet = $('<style type="text/css">')[0];
		var tabStyleRule = '.' + accHiddenClass;
		tabStyleRule += '{position:absolute !important;left:-9999px !important;top:-9999px !important;display:block !important; width: 100% !important;}';
		if (tabStyleSheet.styleSheet) {
			tabStyleSheet.styleSheet.cssText = tabStyleRule;
		} else {
			tabStyleSheet.appendChild(document.createTextNode(tabStyleRule));
		}
		$('head').append(tabStyleSheet);
	}());

	return SlideAccordion;
}));


/*
 * Responsive Layout helper
 */
window.ResponsiveHelper = (function($){
	// init variables
	var handlers = [],
		prevWinWidth,
		win = $(window),
		nativeMatchMedia = false;

	// detect match media support
	if(window.matchMedia) {
		if(window.Window && window.matchMedia === Window.prototype.matchMedia) {
			nativeMatchMedia = true;
		} else if(window.matchMedia.toString().indexOf('native') > -1) {
			nativeMatchMedia = true;
		}
	}

	// prepare resize handler
	function resizeHandler() {
		var winWidth = win.width();
		if(winWidth !== prevWinWidth) {
			prevWinWidth = winWidth;

			// loop through range groups
			$.each(handlers, function(index, rangeObject){
				// disable current active area if needed
				$.each(rangeObject.data, function(property, item) {
					if(item.currentActive && !matchRange(item.range[0], item.range[1])) {
						item.currentActive = false;
						if(typeof item.disableCallback === 'function') {
							item.disableCallback();
						}
					}
				});

				// enable areas that match current width
				$.each(rangeObject.data, function(property, item) {
					if(!item.currentActive && matchRange(item.range[0], item.range[1])) {
						// make callback
						item.currentActive = true;
						if(typeof item.enableCallback === 'function') {
							item.enableCallback();
						}
					}
				});
			});
		}
	}
	win.bind('load resize orientationchange', resizeHandler);

	// test range
	function matchRange(r1, r2) {
		var mediaQueryString = '';
		if(r1 > 0) {
			mediaQueryString += '(min-width: ' + r1 + 'px)';
		}
		if(r2 < Infinity) {
			mediaQueryString += (mediaQueryString ? ' and ' : '') + '(max-width: ' + r2 + 'px)';
		}
		return matchQuery(mediaQueryString, r1, r2);
	}

	// media query function
	function matchQuery(query, r1, r2) {
		if(window.matchMedia && nativeMatchMedia) {
			return matchMedia(query).matches;
		} else if(window.styleMedia) {
			return styleMedia.matchMedium(query);
		} else if(window.media) {
			return media.matchMedium(query);
		} else {
			return prevWinWidth >= r1 && prevWinWidth <= r2;
		}
	}

	// range parser
	function parseRange(rangeStr) {
		var rangeData = rangeStr.split('..');
		var x1 = parseInt(rangeData[0], 10) || -Infinity;
		var x2 = parseInt(rangeData[1], 10) || Infinity;
		return [x1, x2].sort(function(a, b){
			return a - b;
		});
	}

	// export public functions
	return {
		addRange: function(ranges) {
			// parse data and add items to collection
			var result = {data:{}};
			$.each(ranges, function(property, data){
				result.data[property] = {
					range: parseRange(property),
					enableCallback: data.on,
					disableCallback: data.off
				};
			});
			handlers.push(result);

			// call resizeHandler to recalculate all events
			prevWinWidth = null;
			resizeHandler();
		}
	};
}(jQuery));