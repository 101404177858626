export default function initEditableInput() {
  let elements = document.querySelectorAll('.editable-field');

  if (elements.length == 0) return;

  elements.forEach(element => {
    let input = element.querySelector('input');
    let editBtn = element.querySelector('.edit-btn');

    editBtn.addEventListener('click', function(e) {
      e.preventDefault();

      if(!input.value) {
        input.value = 0;
      }

      if (input.disabled == true) {
        input.disabled = false;
        editBtn.textContent = 'Save';
      } else {
        input.disabled = true;
        editBtn.textContent = 'Edit';
      }
    })

    input.addEventListener('input', resizeInput);
    resizeInput.call(input);
  })

  function resizeInput() {
    this.style.width = this.value.length + "ch";
  }
}
