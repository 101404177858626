import intlTelInput from 'intl-tel-input';

export default function initPhoneInput() {
  const inputs = document.querySelectorAll(".js-phone-input");

  if (inputs.length == 0) return;

  inputs.forEach(input => {
    intlTelInput(input, {
      initialCountry: "auto",
      separateDialCode: true,
      geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback("us"));
      },
      loadUtils: () => import("intl-tel-input/utils"),
    });
  });
}
