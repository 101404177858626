/* eslint-disable */
import { Fancybox } from "@fancyapps/ui";

const initFancybox = () => {
  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });
}

export default initFancybox;
