import Swiper from 'swiper';
import { Keyboard, A11y, EffectFade, Navigation, Pagination, Autoplay } from "swiper/modules";

export default function initIntroSlider() {
  const swiper = new Swiper('.js-intro-slider', {
    modules: [Keyboard, A11y, Pagination, EffectFade, Navigation, Autoplay],
    loop: true,
    spaceBetween: 30,
    allowTouchMove: false,
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    a11y: {
      prevSlideMessage: 'Previous slide',
      nextSlideMessage: 'Next slide',
      notificationClass: 'swiper-notification visually-hidden'
    },
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  });
}