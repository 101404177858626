import MobileNav from '../plugins/mobileNavPlugin';
import '../plugins/openClosePlugin';

const menuHolder = document.querySelector('body');
const mobileNav = new MobileNav(menuHolder, {
  outSideSelector: '.header',
  onShow() {
    window.scrollTo({top: 0, behavior: "smooth"});
    mobileNav.btnOpeners.forEach((btn) => {
      btn.setAttribute('aria-expanded', 'true');
    });
    document.addEventListener('keydown', handleKeyPress);
  },
  onHide() {
    mobileNav.btnOpeners.forEach((btn) => {
      btn.setAttribute('aria-expanded', 'false');
    });
    mobileNav.btnOpeners[0].focus();
    document.removeEventListener('keydown', handleKeyPress);
  },
});

function initMobileNav() {
  ResponsiveHelper.addRange({
		'..1023': {
			on: function() {
        mobileNav.init();
			},
			off: function() {
        mobileNav.destroy();
			}
		}
	});
}

function handleKeyPress(event) {
  const keys = getKeyboardFocusableElements(mobileNav.outSideContainer);
  const firstItem = keys.slice(0)[0];
  const lastItem = keys.slice(-1)[0];
  let currentItem = document.activeElement;

  if (event.shiftKey && event.keyCode == 9) {
    if (currentItem == firstItem) {
      setTimeout(() => {
        lastItem.focus()
      }, 50)
      event.preventDefault();
    }
  } else if (event.key == "Tab") {
    if (currentItem == lastItem) {
      setTimeout(() => {
        firstItem.focus()
      }, 50)
      event.preventDefault();
    }
  } else if (event.key == "Escape") {
    mobileNav.hide();
  }
}

function getKeyboardFocusableElements(element = document) {
  return [
    ...element.querySelectorAll(
      'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])',
    ),
  ].filter(
    el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden'),
  )
}

function initNavDropdown() {
  jQuery('.main-nav .menu-item-has-children').openClose({
    activeClass: 'active',
    opener: '>.nav-link',
    slider: '.drop-holder',
    animSpeed: 100,
    effect: 'fade',
    hideOnClickOutside: true,
  });

  ResponsiveHelper.addRange({
		'1024..': {
			on: function() {
        jQuery('.main-nav .menu-item-has-children').openClose('isFade')
        jQuery('.main-nav .menu-item-has-children').openClose('hideSlide')
			},
			off: function() {
        jQuery('.main-nav .menu-item-has-children').openClose('isSlide')
        jQuery('.main-nav .menu-item-has-children').openClose('hideSlide')
			}
		}
	});

  if (window.innerWidth < 1024) {
    jQuery('.main-nav .menu-item-has-children').openClose('isSlide')
  } else {
    jQuery('.main-nav .menu-item-has-children').openClose('isFade')
  }
}

export {initMobileNav, initNavDropdown}
