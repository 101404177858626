import JustValidate from 'just-validate';

export default function initApplyLoanForm() {
  const section = document.querySelector('.apply-loan-section');

  if (!section) return;
  const ajaxurl = pathInfo.admin_ajax;
  const redirect_url = pathInfo.api_redirect;
  const form = document.getElementById('js-apply-loan-form');
  const LOADINGCLASS = 'is-loading';
  const amountHolder = section.querySelector('.js-loan-amount');
  const amountInput = section.querySelector('.js-loan-amount-input');
  const amountCoefficient = 12;

  const getFormData = () => {
    const formData = {
      'user': {
        'email': document.getElementById('apply-loan-email').value,
        'password': document.getElementById('apply-loan-password').value,
        'first_name': document.getElementById('apply-loan-name').value,
        'last_name': document.getElementById('apply-loan-lastname').value,
        'phone_number': document.getElementById('apply-loan-phone').value,
        'accepted_tcs': 1
      },
      'lead': {
        'company_name': document.getElementById('apply-loan-company').value,
        'lead_amount': document.getElementById('funding_amount').value,
        'annual_revenue': document.getElementById('annual_turnover').value,
      },
    }

    return formData;
  }

  const displayErrors = (errors) => {
    const errorContainer = document.getElementById('errors-list');
    errorContainer.innerHTML = '';

    const ul = document.createElement('ul');


  if (errors.error && typeof errors.error === 'object') {
    for (const field in errors.error) {
      if (errors.error.hasOwnProperty(field)) {
        const errorMessages = errors.error[field];

        if (Array.isArray(errorMessages)) {
          errorMessages.forEach(message => {
            const li = document.createElement('li');
            li.textContent = `${field}: ${message}`;
            ul.appendChild(li);
          });
        } else {
          console.log(`Expected an array for field ${field}, but got:`, errorMessages);
        }
      }
    }
  } else {
    console.error('Expected errors.error to be an object. Current value:', errors.error);
  }
    errorContainer.appendChild(ul);
  }

  const sendData = (data) => {
    const formData = new FormData(form);
    formData.append( 'action', 'apply_loan' );
    formData.append( 'data', JSON.stringify(data) );
    section.classList.add(LOADINGCLASS);

    fetch(ajaxurl, {
      method: 'post',
      credentials: 'same-origin',
      body: formData,
    })
    .then(response => {
      return response.text()
    })
    .then(data => {
      let parsedData;
      try {
        parsedData = typeof data === 'string' ? JSON.parse(data) : data;
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return;
      }
      if(parsedData.hasOwnProperty('token') && parsedData.token !== '') {
        const token = parsedData.token;
        const link = redirect_url + '?jwt=' + token;
        window.location.href = link;
      } else {
        displayErrors(parsedData);
      }
    })
    .finally(() => {
      section.classList.remove(LOADINGCLASS);
    });
  }

  const validateStep03 = () => {
    if(form) {
      const validator = new JustValidate('#js-apply-loan-form', {
        validateBeforeSubmitting: true,
      })

      validator
        .addField('#apply-loan-name', [
          {
            rule: 'required',
          },
          {
            rule: 'minLength',
            value: 3,
          },
          {
            rule: 'maxLength',
            value: 30,
          },
        ])
        .addField('#apply-loan-lastname', [
          {
            rule: 'required',
          },
          {
            rule: 'minLength',
            value: 3,
          },
          {
            rule: 'maxLength',
            value: 30,
          },
        ])
        .addField('#apply-loan-company', [
          {
            rule: 'required',
          },
          {
            rule: 'minLength',
            value: 3,
          },
          {
            rule: 'maxLength',
            value: 30,
          },
        ])
        .addField('#apply-loan-email', [
          {
            rule: 'required',
            errorMessage: 'Email is required',
          },
          {
            rule: 'email',
            errorMessage: 'Email is invalid!',
          },
        ])
        .addField('#apply-loan-password', [
          {
            rule: 'required',
          },
          {
            rule: 'password',
          },
        ])
        .addField('#apply-loan-phone', [
          {
            rule: 'required',
          },
        ])
        .addField('#accept-check', [
          {
            rule: 'required',
          },
        ]);

      validator.onSuccess((event) => {
        event.preventDefault();

        sendData(getFormData());
      })
    }
  }

  function checkStepStatus(input, button) {
    if(input.value) {
      button.disabled = false;
    } else {
      button.disabled = true;
    }

    input.addEventListener('input', () => {
      if(input.value) {
        button.disabled = false;
      } else {
        button.disabled = true;
      }
    })
  }

  const calculateAmount = () => {
    if(!amountInput && !amountHolder) return;
    const amount = amountInput.value / amountCoefficient;
    amountHolder.textContent = amount.toLocaleString('en-US');
  }

  function formStep01() {
    let formStep01 = document.getElementById('js-apply-loan-form-01');
    let btnStep01 = document.getElementById('apply-loan-btn-1');
    let inputStep01 = document.getElementById('apply-loan-step-1');

    if (!formStep01) return;

    const action = formStep01.action;
    const errorAction = formStep01.getAttribute('data-error_url');

    formStep01.addEventListener('submit', (e) => {
      e.preventDefault();
      
      if (inputStep01.value < 120000) {
        formStep01.action = errorAction;
      } else {
        formStep01.action = action;
      }

      formStep01.submit()
    })

    if(btnStep01 && inputStep01) {
      checkStepStatus(inputStep01, btnStep01);
    }
  }

  function formStep02() {
    let formStep02 = document.getElementById('js-apply-loan-form-02');
    let btnStep02 = document.getElementById('apply-loan-btn-2');
    let inputStep02 = document.getElementById('apply-loan-step-2');

    if (!formStep02) return;

    const action = formStep02.action;
    const errorAction = formStep02.getAttribute('data-error_url');

    formStep02.addEventListener('submit', (e) => {
      e.preventDefault();
      
      if (inputStep02.value < 1) {
        formStep02.action = errorAction;
      } else {
        formStep02.action = action;
      }

      formStep02.submit()
    })

    if(btnStep02 && inputStep02) {
      checkStepStatus(inputStep02, btnStep02);
    }
  }

  formStep01();
  formStep02();
  validateStep03();
}